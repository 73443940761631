import { initial } from 'lodash';

const defaultInputState = {
  id: null,
  name: '',
  billingReference: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  stateProvince: '',
  country: '',
  rhinestahlEmailContact: '',
  qualitycontrolEmailContact: '',
  zipPostal: '',
  active: true,
  parentTenantId: null,
  regionid: null,
};

const state = {
  tenant: {
    searchResults: [],
    searchInput: '',
    isEditable: false,
    input: defaultInputState,
    initialInput: {},
    labels: [],
    labelSearchInput: '',
    activeFilter: 'All',
    tenants: [],
    parentDropdownOptions: [],
  },
};

export { state as default, defaultInputState };
